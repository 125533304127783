import React, { useState, useContext, useRef } from "react"
import { useLocation } from "@reach/router"
import { Transition, Popover, Dialog } from "@headlessui/react"
import { useToasts } from "react-toast-notifications"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import { StaticImage } from "gatsby-plugin-image"
import { faTimes as faTimesLight } from "@fortawesome/pro-light-svg-icons"
import {
  faMapMarkerExclamation,
  faChartLine,
  faBoxBallot,
  faTasks,
} from "@fortawesome/pro-duotone-svg-icons"

import { Fragment } from "react"
import { Link } from "gatsby"
import { myContext } from "../context"

function Nav() {
  const { addToast } = useToasts()
  const [isOpen, setIsOpen] = useState(false)

  const value = useContext(myContext)

  const location = useLocation()

  //These sub-menus need to be resuable
  const solutions = [
    {
      name: "Surveys",
      description: "Measure actions your users take",
      href: "/Solutions/Surveys",
      icon: faTasks,
    },
    {
      name: "Election Results",
      description: "Create your own targeted content",
      href: "/Solutions/Elections",
      icon: faBoxBallot,
    },
    {
      name: "Indicators",
      description: "Keep track of your growth",
      href: "/Solutions/Indicators",
      icon: faChartLine,
    },
    {
      name: "Incidents",
      description: "Keep track of your growth",
      href: "/Solutions/Incidents",
      icon: faMapMarkerExclamation,
    },
  ]

  const handleSubmit = e => {
    e.preventDefault()
    addToast(
      "Thank you for your interest!\n\nWe look forward to speaking with you soon!",
      { appearance: "success", autoDismiss: true }
    )
    var _hsp = (window._hsp = window._hsp || [])

    if (_hsp) _hsp.push(["revokeCookieConsent"]) // remove hubspot cookies after submit
    value.closeModal()
  }

  const loadHubspotJS = e => {
    if (window.hubspotLoaded) return // prevent from loading multiple times
    var script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://js.hs-scripts.com/3458635.js"
    document.getElementsByTagName("head")[0].appendChild(script)
    window.hubspotLoaded = true
  }

  const buttonRef = useRef(null)
  const timeoutDuration = 350
  let timeout

  const openMenu = () => {
    buttonRef && buttonRef.current.click()
  }

  const closeMenu = () => buttonRef && buttonRef.current.click()

  const onMouseEnter = closed => {
    clearTimeout(timeout)
    closed && openMenu()
  }
  const onMouseLeave = open => {
    open && (timeout = setTimeout(() => closeMenu(), timeoutDuration))
  }

  return (
    <header className="sticky top-0 z-10 shadow">
      <Transition appear show={value.isMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={value.closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-30" />
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl z-50 p-5 overflow-hidden text-left align-middle transition-all transform bg-white shadow rounded-2xl">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form
                    className="form"
                    id="ServiceRequestForm"
                    onSubmit={e => handleSubmit(e)}
                  >
                    <div className="overflow-hidden sm:rounded-md px-1">
                      <div className="text-right">
                        <button
                          className="outline-none border-none"
                          type="button"
                          onClick={() => value.closeModal()}
                        >
                          <FontAwesomeIcon icon={faTimesLight} size="lg" />
                        </button>
                      </div>
                      <div className="inline-flex justify-between w-full">
                        <Dialog.Title
                          as="h1"
                          className="text-2xl sm:text-4xl leading-6 text-gray-900 bg-white"
                        >
                          Contact Us
                        </Dialog.Title>
                      </div>
                      <div className="py-5 bg-white">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="name"
                              className="block  text-sm font-medium text-gray-900"
                            >
                              Name
                            </label>
                            <input
                              placeholder="Your name"
                              required
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="given-name"
                              className="mt-1 placeholder-gray-400 block w-full shadow-sm sm:text-sm border-gray-500 rounded"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email_address"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Email
                            </label>
                            <input
                              placeholder="Write your email address"
                              required
                              type="email"
                              name="email_address"
                              id="email_address"
                              autoComplete="email"
                              onInput={() => loadHubspotJS()}
                              className="mt-1 placeholder-gray-400 block w-full shadow-sm sm:text-sm border-gray-500 rounded"
                            />
                          </div>
                          <div className="col-span-6">
                            <label
                              htmlFor="organization"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Organization
                            </label>
                            <input
                              placeholder="Write your organization name..."
                              required
                              type="text"
                              name="organization"
                              id="organization"
                              className="mt-1 placeholder-gray-400 block w-full shadow-sm sm:text-sm border-gray-500 rounded"
                            />
                          </div>
                          <div className="col-span-6">
                            <label
                              htmlFor="message"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Message
                            </label>
                            <div className="mt-1">
                              <textarea
                                defaultValue={value.message}
                                id="message"
                                name="message"
                                required
                                placeholder="Write a brief description..."
                                rows={3}
                                className="shadow-sm  placeholder-gray-400  mt-1 block w-full sm:text-sm border-gray-600 rounded"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center flex-wrap">
                        <div className="items-center flex flex-wrap justify-center gap-4">
                          <button
                            type="submit"
                            id="RequestSubmitButton"
                            className="btn-primary"
                          >
                            Send your message
                          </button>
                        </div>
                        <p className="ml-4">
                          Or, write to us at{" "}
                          <a
                            href="mailto:info@keshif.me"
                            className="text-purple-600"
                          >
                            info@keshif.me
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-center items-center flex-col space-y-4 text-left font-light">
                      <p className="subtitle">
                        We are committed to your{" "}
                        <Link className="text-purple-600" to="/Privacy">
                          privacy
                        </Link>
                        . We will only use your information to contact you about
                        our services. We store your information in our customer
                        management system. We don't share your information with
                        other third parties.
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <nav className="bg-white">
        <div className="container">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center flex-1">
              <div className="flex-shrink-0">
                <a className="disable-underline" href="/">
                  <StaticImage
                    placeholder="blurred"
                    alt="Keshif Logo"
                    width="150"
                    src="../../images/logo.png"
                  />
                </a>
              </div>
              <div className="hidden md:block ">
                <div className="ml-4 lg:ml-14 flex items-baseline space-x-4 lg:space-x-8">
                  <Link
                    activeClassName="text-purple-600"
                    to="/Technology"
                    className="text-gray-900 hover:text-purple-600 font-medium group py-1 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                  >
                    Technology
                  </Link>
                  <Link
                    activeClassName="text-purple-600"
                    to="/Services"
                    className="text-gray-900 hover:text-purple-600 font-medium group py-1 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                  >
                    Services
                  </Link>
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          onMouseLeave={() => onMouseLeave(open)}
                          onClick={openMenu}
                          onFocus={() => onMouseEnter(!open)}
                          onMouseEnter={() => onMouseEnter(!open)}
                          ref={buttonRef}
                          as="button"
                          className={`${
                            location.pathname.includes("/Solutions")
                              ? "text-purple-600"
                              : "text-gray-900"
                          } hover:text-purple-600 font-medium group py-1 rounded-md inline-flex items-center text-sm cursor-pointer`}
                        >
                          <span>Solutions</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            size="xs"
                            className="ml-1 h-5 w-5 group-hover:text-opacity-80 transition ease-in-out duration-150"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-350"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-10 w-56 px-4 mt-3 transform -translate-x-14 sm:px-0">
                            <div className="rounded-md shadow-xl">
                              <div
                                onMouseEnter={() => onMouseEnter(!open)}
                                onMouseLeave={() => onMouseLeave(open)}
                                className="relative grid bg-white p-1"
                              >
                                {solutions.map(item => (
                                  <Link
                                    key={item.name}
                                    to={item.href}
                                    className="flex text-gray-900 hover:text-purple-600 font-medium items-center disable-underline py-2 px-4 transition duration-150 ease-in-out rounded-lg hover:bg-blue-100 focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                  >
                                    <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center text-xl">
                                      <FontAwesomeIcon
                                        icon={item.icon}
                                        className="text-purple-600"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="ml-4">
                                      <p className="text-sm min-w-max">
                                        {item.name}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <Link
                    partiallyActive={true}
                    activeClassName="text-purple-600"
                    to="/Projects"
                    className="text-gray-900 hover:text-purple-600 font-medium group py-1 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                  >
                    Projects
                  </Link>
                  <Link
                    activeClassName="text-purple-600"
                    to="/AboutUs"
                    className="text-gray-900 hover:text-purple-600 font-medium group py-1 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                  >
                    About
                  </Link>
                </div>
              </div>
            </div>
            <button
              onClick={() => value.openModal()}
              type="button"
              className="btn-secondary"
            >
              Contact Us
            </button>
            <div className="flex md:hidden ml-4">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-purple-600 inline-flex items-center justify-center p-2 w-9 rounded-md text-white hover:opacity-90 focus:ring-2 focus:ring-offset-1 focus:ring-offset-purple-600"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <FontAwesomeIcon color="white" icon={faBars} />
                ) : (
                  <FontAwesomeIcon color="white" icon={faTimes} />
                )}
              </button>
            </div>
          </div>
        </div>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {ref => (
            <div className="lg:hidden min-h-screen" id="mobile-menu">
              <div ref={ref} className="pt-5 space-y-1 grid">
                <Link
                  activeClassName="text-purple-600 bg-blue-100"
                  to="/"
                  className="text-gray-900 hover:text-purple-600 disable-underline hover:bg-blue-100 font-medium group pl-10 py-3 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                >
                  Home
                </Link>
                <Link
                  activeClassName="text-purple-600 bg-blue-100"
                  to="/Technology"
                  className="text-gray-900 hover:text-purple-600 disable-underline hover:bg-blue-100 font-medium group pl-10 py-3 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                >
                  Technology
                </Link>
                <Link
                  activeClassName="text-purple-600"
                  to="/Services"
                  className="text-gray-900 hover:text-purple-600 disable-underline hover:bg-blue-100 font-medium group pl-10 py-3 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                >
                  Services
                </Link>
                <div className="text-gray-900 hover:text-purple-600 disable-underline hover:bg-blue-100 font-medium group pl-10 py-3 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75">
                  <span>Solutions</span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="xs"
                    className="ml-1 h-5 w-5 group-hover:text-opacity-80 transition ease-in-out duration-150"
                    aria-hidden="true"
                  />
                </div>
                {solutions.map(item => (
                  <Link
                    activeClassName="text-purple-600 bg-blue-100"
                    key={item.name}
                    to={item.href}
                    className="ml-5 text-gray-900 hover:text-purple-600 disable-underline hover:bg-blue-100 font-medium group pl-10 py-3 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                  >
                    {item.name}
                  </Link>
                ))}
                <Link
                  partiallyActive={true}
                  activeClassName="text-purple-600 bg-blue-100"
                  to="/Projects"
                  className="text-gray-900 hover:text-purple-600 disable-underline hover:bg-blue-100 font-medium group pl-10 py-3 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                >
                  Projects
                </Link>
                <Link
                  activeClassName="text-purple-600 bg-blue-100"
                  to="/AboutUs"
                  className="text-gray-900 hover:text-purple-600 disable-underline hover:bg-blue-100 font-medium group pl-10 py-3 rounded-md inline-flex items-center text-sm  focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75"
                >
                  About
                </Link>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </header>
  )
}

export default Nav
